@charset "utf-8"

// Primary colors
$PREZERO-PETROL: #00343d
$PREZERO-BRIGHT-GREEN: #97d700
$PREZERO-ROYAL-BLUE:  #003cc1

// Secondary colors
$PREZERO-GREEN: #6ba43a
$PREZERO-MINT: #a8d7ce
$PREZERO-LIGHT-BLUE: #5ac5f2
$PREZERO-BLUE: #008ff2
$WHITE: #ffffff
$BLACK: #000000

// Font color
$PREZERO-GREY: #575756

// Signal color
$PREZERO-RED: #ff432e

$PREZERO-BOX-FILL: change-color(#96DBF8, $alpha: 0.2)
$PREZERO-PETROL-LIGHT: change-color($PREZERO-PETROL, $alpha: 0.5)