.navbar
    &.navbar-narrow,
    &.navbar-narrow .container
        font-size: 12px
        min-height: unset !important
    font-size: 17px
    .navbar-item
        border-radius: 0 !important

.section
    position: relative
    overflow-x: hidden
    padding: 120px 0
    .section-jouw-impact
        background-color: aliceblue
        border-radius: 1rem
        padding: 1rem
    .section-overlay
        position: absolute
        background-color: change-color(white, $alpha: 0.8)
        height: 100%
        width: 100%
        z-index: 30 // modal z-index: 40
        left: 0
        right: 0
    &.company-intro
        background: linear-gradient(to bottom, #ffffff,#f9f9f9)
        border-radius: 0 0 160px 0
    &.background-white
        background-color: #ffffff
    &.hero
        // css below copied from prezero.nl
        position: relative
        align-items: center
        flex-direction: row
        overflow: hidden
        background-position: center
        background-repeat: no-repeat
        background-size: 100% 100%
        border-radius: 0px 0px 120px 0px

        // deducted by navbar height (3.25rem) and navbar padding (1rem * 2)
        height: calc(100vh - 3.25rem - 2rem)


.contact-info
    li
        line-height: 2rem
        padding: 2px 0 !important
    .title
        color: #000
