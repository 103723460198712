@charset "utf-8"
$font-path: './public/fonts/'

@font-face
    font-family: "Helsinki Thin"
    src: url("#{$font-path}/Helsinki Thin.otf") format("opentype")
    font-weight: 200
    font-style: normal
    font-stretch: 50% 200% /* Required by Chrome */
    font-variation-settings: 'wdth' 100

@font-face
    font-family: "Helsinki Extralight"
    src: url("#{$font-path}/Helsinki Extralight.otf") format("opentype")
    font-weight: 200
    font-style: normal
    font-stretch: 50% 200% /* Required by Chrome */

$FAMILY-THIN: "Helsinki Thin", "Tahoma", sans-serif
$FAMILY-EXTRALIGHT: "Helsinki Extralight", "Tahoma", sans-serif