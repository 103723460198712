// || 0. CUSTOMIZED VARIABLES
@import "./stylesheet/fonts"
@import "./stylesheet/colors"
@import "./stylesheet/prezero"


// || 1. Import the initial variables
@import "~bulma/sass/utilities/initial-variables"
@import "~bulma/sass/utilities/functions"


// || 2. Set your own initial variables
$family-serif: $FAMILY-THIN
$body-size: 18px

// || 3. Set the derived variables
$family-primary:   $FAMILY-THIN
$family-secondary: $FAMILY-EXTRALIGHT
$body-family:      $family-serif

$size-2: 2.25rem
$weight-normal: 200
$weight-medium: 200
$weight-semibold: 200

$primary:      $PREZERO-ROYAL-BLUE
$background:   $white
$title-color:  $black
$text:         $black
$link:         $primary

// || 4. Customisation

td.numerical-value,
th.numerical-value,
p.numerical-value
    text-align: right !important
    padding-right: 0.5em !important

.hero-body
    padding: unset !important // overwrite default hero-body paddings

// headings: see https://bulma.io/documentation/elements/title/ for font-size setup etc.
.title
    font-family: $FAMILY-THIN

.rounded-corner
    &.is-landing
        img
            border-radius: 0 0 160px 0
    &.is-bottom-right-corner
        img
            border-radius: 0 0 80px 0
    &.is-two-corners
        img
            border-radius: 80px 0

.gallery
    position: relative
    img.picture
        width: unset !important
        margin-left: auto
        margin-right: auto
    .overlay-text
        font-size: $size-6
        vertical-align: middle
        text-align: center
        background: $PREZERO-ROYAL-BLUE
        color: $white
        border-radius: 5px
        padding: 0.5em 1em
        margin: auto
        position: absolute
        min-width: max-content
        top: 50%
        left: 50%
        -webkit-transform: translate(-50%, -50%) /* iOS */
        transform: translate(-50%, -50%)
        align-items: center
        justify-content: center

nav.navbar
    flex-wrap: wrap
    .navbar-menu
        justify-content: center
        .navbar-item
            font-family: $FAMILY-THIN
            color: $title-color
            background-color: $WHITE

    a:not(.navbar-burger):hover
        color: $PREZERO-BRIGHT-GREEN !important
        &::before
            content: ''
            position: absolute
            width: 90%
            opacity: 1
            border-bottom: 4px solid $PREZERO-BRIGHT-GREEN
            left: 5%
            bottom: -1.5rem
    a.navbar-item.is-active
        &::before
            content: ''
            position: absolute
            width: 90%
            opacity: 1
            border-bottom: 4px solid $PREZERO-BRIGHT-GREEN
            left: 5%
            bottom: -1.5rem

.box.info-card
    background: $PREZERO-BOX-FILL
    color: $PREZERO-PETROL
    height: 100%
    .title,
    .label,
    a:not(.button)
        color: inherit

.message-header
    border-radius: 0 !important
    border-top: 1px solid change-color($PREZERO-GREY, $lightness: 70%)
    border-bottom: 1px solid change-color($PREZERO-GREY, $lightness: 70%)
    background-color: $PREZERO-BOX-FILL !important
    color: $PREZERO-PETROL !important
    .icon
        position: relative
        pointer-events: auto
        color: $PREZERO-PETROL
    &:hover
        cursor: pointer

.logo
    object-fit: contain
    width: 150px
    padding-bottom: 0.5em

table.data-grid
    .cell
        vertical-align: middle !important
        text-align: left !important
        padding: 5px !important
        &.read-only
            color: #000 !important
        &.selected.editing > input
            height: 1rem
            vertical-align: middle
            font-size: inherit
            border: none

// dropdown in react-datatsheet
.select.react-datasheet
    font-size: 1em
    height: 1.25em !important
    select,
    select:focus
        padding-left: 0 !important
        min-width: fit-content !important
        border: none
        background: none
        box-shadow: none
        padding-top: 0
        padding-bottom: 0
        height: 1.25em


.recharts-responsive-container
    margin: auto
    .recharts-wrapper
        .recharts-tooltip-wrapper
            .custom-tooltip
                color: $BLACK
                background-color: $WHITE
                border: 1px solid rgb(204, 204, 204)
                margin: 5px
                padding: 10px
                max-width: 300px
                .title
                    color: $BLACK
                    margin-bottom: 0.25em
                p,
                .title
                    font-size: 16px
                .jouw-impact
                    color: $PREZERO-ROYAL-BLUE
                .sector-avg
                    color: $PREZERO-LIGHT-BLUE
        .recharts-legend-wrapper
            padding-top: 1em
        .recharts-bar
            tspan // label above bars
                font-size: 12px
            path
                padding: 1em
        .recharts-cartesian-axis
            tspan
                font-size: 16px

.verklein-impact
    &.tips-list
        .title:not(.is-1, .is-2)
            margin-top: 0.75rem
            margin-bottom: 0.25rem
        p
            text-align: justify

footer
    padding-bottom: 50px !important
    background-color: $WHITE !important

.box.cta
    height: auto
    ul
        list-style: none
        li:before
            padding-right: .5em
            content: '✓'

.container-with-border
    border: 1px solid grey
    border-radius: 10px
    padding: 1.5rem 1.5rem !important
    margin-bottom: 1rem

.dropdown
    &.onhover-bg-grey
        padding: 5px
        &:hover
            background-color: #fafafa
            width: 100%
            cursor: default

@media print
    body
        -webkit-print-color-adjust: exact
        color-adjust: exact
    .print-full-page
        page-break-after: always
        page-break-inside: avoid
    @page
        size: portrait // https://developer.mozilla.org/en-US/docs/Web/CSS/@page/size
        margin: 1cm

// || 5. Add new color variables to the color map, if applicable
@import "~bulma/sass/utilities/derived-variables"
@import "~bulma-tooltip/dist/css/bulma-tooltip.min.css"
@import "~bulma-switch/dist/css/bulma-switch.sass"
@import "~bulma-checkradio/dist/css/bulma-checkradio.sass"
@import "~react-datasheet/lib/react-datasheet.css"

// || 6. Import the rest of Bulma
@import "~bulma/bulma"
